import React from "react";
import { ALink } from "../../components/atoms/StyledLink";
import { Link } from "gatsby";

export const impact = (
  <>
    <p>
      Kozáková R.,&nbsp;
      <Link to="/people/" state={{ person: "bobek" }}>
        Bobek P.
      </Link>
      , Dreslerová D., Abraham V. & Svobodová-Svitavská H. (2020). The
      prehistory and early history of the Šumava Mountains (Czech Republic) as
      seen through anthropogenic pollen indicators and charcoal data.
      <em>The Holocene</em> 31: 145-159.&nbsp;
      {/*       <ALink href="https://paris.ibot.cas.cz/cs/entries/23229">
        Download from journal
      </ALink> */}
    </p>
    <p>
      Hájek M.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
       & 
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
       (2020). A novel dataset of permanent plots in extremely species-rich
      temperate grasslands. <em>Folia Geobotanica</em> 55: 257-268.&nbsp;
      {/*       <ALink href="https://paris.ibot.cas.cz/cs/entries/23684">
        Download from journal
      </ALink> */}
    </p>
    <p>
      Petr L., Petřík J., Chattová B.,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      , Rohovec J., Matoušková Š. & Hajnalová M. (2020). The history of a
      Pannonian oak woodland - palaeoecological evidence from south-eastern
      Slovakia. <em>Folia Geobotanica</em> 55: 29-40.&nbsp;
      {/*       <ALink href="https://paris.ibot.cas.cz/cs/entries/23752">
        Download from journal
      </ALink> */}
    </p>
    <p>
      Divíšek J., Hájek M.,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      , Petr L., Večeřa M., Tichý L., Willner W. & Horsák M. (2020). Holocene
      matters: Landscape history accounts for current species richness of
      vascular plants in forests and grasslands of eastern Central Europe. 
      <em>Journal of Biogeography</em> 47: 721-735.&nbsp;
      {/*       <ALink href="https://paris.ibot.cas.cz/cs/entries/23834">
        Download from journal
      </ALink> */}
    </p>
    <p>
      Carter V. A.,&nbsp;
      <Link to="/people/" state={{ person: "bobek" }}>
        Bobek P.
      </Link>
      , Moravcová A.,&nbsp;
      <Link to="/people/" state={{ person: "solcova" }}>
        Šolcová A.
      </Link>
      , Chiverrell R. C., Clear J. L., Finsinger W., Feurdean A., Tanţău I.,
      Magyari E., Brussel T. &amp; Kuneš P. 2020: The role of climate-fuel
      feedbacks on Holocene biomass burning in upper-montane Carpathian
      forests.&nbsp;
      <em>Global and Planetary Change</em>
      &nbsp;193, 1 – 17.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0921818120301557">
        Download from journal
      </ALink>
    </p>
    <p>
      Dreslerová D., Kozáková R., Metlička M., Brychová V.,&nbsp;
      <Link to="/people/" state={{ person: "bobek" }}>
        Bobek P.
      </Link>
      , Čišecký Č., Demján P., Lisá L., Pokorná A., Michálek J., Strouhalová B.
      &amp; Trubač J. 2020: Seeking the meaning of a unique mountain site
      through a multidisciplinary approach. The Late La Tène site at Sklářské
      Valley, Šumava Mountains, Czech Republic.&nbsp;
      <em>Quaternary International</em>
      &nbsp;542, 88 – 108.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S1040618220300987">
        Download from journal
      </ALink>
    </p>
    <p>
      Fajmonová Z. ,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      &nbsp;and Hájek M. 2020: Soil moisture and a legacy of prehistoric human
      activities have contributed to the extraordinary plant species diversity
      of grasslands in the White Carpathians.&nbsp;
      <em>Preslia</em>, 35 – 56.&nbsp;
      <ALink href="http://www.preslia.cz/P201Fajmonova.pdf">
        Download from journal
      </ALink>
    </p>
    <p>
      Feurdean A., Vannière B., Finsinger W., Warren D., Connor S. C., Forrest
      M., Liakka J., Panait A., Werner C., Andrič M.,&nbsp;
      <Link to="/people/" state={{ person: "bobek" }}>
        Bobek P.
      </Link>
      , Carter V. A., Davis B., Diaconu A-C., Dietze E., Feeser I., Florescu G.,
      Gałka M., Giesecke T., Jahns S.,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      , Kajukało K., Kaplan J., Karpińska-Kołaczek M., Kołaczek P., Kuneš P.,
      Kupriyanov D., Lamentowicz M., Lemmen C., Magyari E. K., Marcisz K.,
      Marinova E., Niamir A., Novenko E., Obremska M., Pędziszewska A., Pfeiffer
      M., Poska A., Rösch M., Słowiński M., Stančikaitė M., Szal M.,
      Święta-Musznicka J., Tanţău I., Theuerkauf M., Tonkov S., Valkó O.,
      Vassiljev J., Veski S., Vincze I., Wacnik A., Wiethold J. &amp; Hickler T.
      2020: Fire hazard modulation by long-term dynamics in land cover and
      dominant forest type in eastern and central Europe. Biogeosciences 17,
      1213 – 1230.&nbsp;
      <ALink href="https://bg.copernicus.org/articles/17/1213/2020/">
        Download from journal
      </ALink>
    </p>
    <p>
      Goláňová P., Hajnalová M., Lisá L., Milo P., Petr L.,&nbsp;
      <Link to="/people/" state={{ person: "frankova" }}>
        Fránková M.
      </Link>
      , Kysela J., Flammer P., Kočárová R. &amp; Barta P. 2020: Investigating
      the complex story of one ditch — A multidisciplinary study of ditch infill
      provides insight into the spatial organisation within the oppidum of
      Bibracte (Burgundy, France).&nbsp;
      <em>PLOS ONE</em>
      &nbsp;15, 1 – 32.&nbsp;
      <ALink href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0231790">
        Download from journal
      </ALink>
    </p>
    <p>
      Granai S., Dabkowski J.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Naton H.-G. and Brou L. 2020: Holocene palaeoenvironments from the
      Direndall tufa (Luxembourg) reconstructed from the molluscan succession
      and stable isotope records.&nbsp;
      <em>The Holocene</em>
      &nbsp;30, 982 – 995.&nbsp;
      <ALink href="https://journals.sagepub.com/doi/abs/10.1177/0959683620908659">
        Download from journal
      </ALink>
    </p>
    <p>
      Hájek M., Dítě D., Horsáková V., Mikulášková E., Peterka T., Navrátilová
      J., Jiménez-Alfaro B.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Tichý L., Horsák M. 2020: Towards the pan-European bioindication system:
      Assessing and testing updated hydrological indicator values for vascular
      plants and bryophytes in mires.&nbsp;
      <em>Ecological Indicators</em>
      &nbsp;116, 1 – 10.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S1470160X20304647">
        Download from journal
      </ALink>
    </p>
    <p>
      Hájek M., Horsáková V.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Coufal R., Dítě D., Němec T. and Horsák M. 2020: Habitat extremity and
      conservation management stabilise endangered calcareous fens in a changing
      world.&nbsp;
      <em>Science of the Total Environment</em>
      &nbsp;719, 1 – 13.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0048969719346844">
        Download from journal
      </ALink>
    </p>
    <p>
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "solcova" }}>
        Šolcová A.
      </Link>
      , Frodlová J., Petr L., Dítě D., Hájek M. and Horsák M. 2020: Can
      relict-rich communities be of an anthropogenic origin? Palaeoecological
      insight into conservation strategy for endangered Carpathian travertine
      fens.&nbsp;
      <em>Quaternary Science Reviews</em>
      &nbsp;234, 1 – 13.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0277379119311849">
        Download from journal
      </ALink>
    </p>
    <p>
      Horsák M., Limondin-Lozouet N., Granai S., Dabkowski J., Divíšek J.
      and&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      &nbsp;2020: Colonisation dynamic and diversity patterns of holocene forest
      snail fauna across temperate Europe: The imprint of palaeoclimate changes.
      Quaternary Science Reviews 240 (2020), 1 – 13.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0277379120303292">
        Download from journal
      </ALink>
    </p>
    <p>
      Novák P., Willner W., Zukal D., Kollár J.,&nbsp;
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
      , Świerkosz K., Ewald J., Wohlgemuth T., Csiky J., Onyshchenko V. &amp;
      Chytrý M. 2020: Oak-hornbeam forests of central Europe: a formalized
      classification and syntaxonomic revision.&nbsp;
      <em>Preslia</em>
      &nbsp;92, 1 – 34.&nbsp;
      <ALink href="http://www.preslia.cz/doi/preslia.2020.001.html">
        Download from journal
      </ALink>
    </p>
    <p>
      Peterka T., Syrovátka V., Dítě D.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Hrubanová M., Jiroušek M., Plesková Z., Singh P., Šímová A., Šmerdová E.
      &amp; Hájek M. 2020: Is variable plot size a serious constraint in
      broad-scale vegetation studies? A case study on fens.
      <em>&nbsp;Applied Vegetation Science</em>
      &nbsp;31, 594 – 605.&nbsp;
      <ALink href="https://onlinelibrary.wiley.com/doi/full/10.1111/jvs.12885">
        Download from journal
      </ALink>
    </p>
    <p>
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
      &nbsp;&amp; Řepka R. 2020: Formerly coppiced old growth stands act as
      refugia of threatened biodiversity in a managed steppic oak forest.&nbsp;
      <em>Forest Ecology and Management</em>
      &nbsp;472, 1 – 7.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0378112720310148?dgcid=rss_sd_all">
        Download from journal
      </ALink>
    </p>
    <p>
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "svitavska" }}>
        Svitavská Svobodová H.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "dudova" }}>
        Dudová L.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Kletetschka G., Kuneš P. &amp; Abraham V. 2020: Conservation targets
      from the perspective of a palaeoecological reconstruction: the case study
      of Dářko peat bog in the Czech Republic.&nbsp;
      <em>Preslia</em>
      &nbsp;92, 87 – 114.&nbsp;
      <ALink href="http://www.preslia.cz/doi/preslia.2020.087.html">
        Download from journal
      </ALink>
    </p>
    <p>
      Schafstall N., Whitehouse N., Kuosmanen N.,&nbsp;
      <Link to="/people/" state={{ person: "svitavska" }}>
        Svitavská-Svobodová H.
      </Link>
      , Saulnier M., Chiverrell R. C., Fleischer P., Kuneš P. &amp; Clear J. L.
      2020: Changes in species composition and diversity of a montane beetle
      community over the last last millennium in High Tatras, Slovakia:
      Implications for forest conservation and management.&nbsp;
      <em>Palaeogeography, Palaeoclimatology, Palaeoecology</em>
      &nbsp;555, 1 – 11.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S0031018220302790">
        Download from journal
      </ALink>
    </p>
    <p>
      <Link to="/people/" state={{ person: "solcova" }}>
        Šolcová A.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      , Horsák M., Pařil P., Petr L., Heiri O., Květoň J., Křížek M., Hartvich
      F., Hájek M. and&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , 2020: Abrupt vegetation and environmental change since the MIS 2: A
      unique paleorecord from Slovakia (Central Europe).&nbsp;
      <em>Quaternary</em>
      &nbsp;
      <em>Science Reviews</em>
      &nbsp;230, 1 – 16.&nbsp;
      <ALink href="https://www.sciencedirect.com/science/article/abs/pii/S027737911931087X">
        Download from journal
      </ALink>
    </p>
    <p>
      Wiezik M., Petr L.,&nbsp;
      <Link to="/people/" state={{ person: "jankovska" }}>
        Jankovská V.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      , Hrivnák R., Korená Hillayová M., Jarčuška B., Máliš F. and Hájek M.
      2020: Western-Carpathian mountain spruce woodlands at their southern
      margin: natural or anthropogenic origin?&nbsp;
      <em>Preslia</em>
      &nbsp;92, 115 – 135.&nbsp;
      <ALink href="http://www.preslia.cz/P202Wiezik.pdf">
        Download from journal
      </ALink>
    </p>
    <p>
      Wiezik M.,&nbsp;
      <Link to="/people/" state={{ person: "jamrichova" }}>
        Jamrichová E.
      </Link>
      ,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      &nbsp;Hrivnák R., Máliš F., Petr L.,&nbsp;
      <Link to="/people/" state={{ person: "jankovska" }}>
        Jankovská V.
      </Link>
      , Čierniková M. &amp; Hájek M. 2020: The Last Glacial and Holocene history
      of mountain woodlands in the southern part of the Western Carpathians,
      with emphasis on the spread of&nbsp;
      <em>Fagus sylvatica</em>
      .&nbsp;
      <em>Palynology</em>
      &nbsp;44, 709 – 722.&nbsp;
      <ALink href="https://www.tandfonline.com/doi/abs/10.1080/01916122.2019.1690066">
        Download from journal
      </ALink>
    </p>
  </>
);

export const others = (
  <>
    <p>
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Peterka T. & 
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
       (2020). Interesting records of rare bryophytes in old fen deposits 3 . 
      <em>Bryonora</em> 66: 1-7.
    </p>
    <p>
      Horsák M.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
       (2020). Kulturní, a přesto cenné. Nečekaný původ reliktních karpatských
      mokřadů. <em>Vesmír</em> 99: 2-5.
    </p>
    <p>
      Adámek M. &amp;&nbsp;
      <Link to="/people/" state={{ person: "bobek" }}>
        Bobek P.
      </Link>
      &nbsp;2020: Oheň v naší krajině. <em>Živa</em> 68, 222 – 224
    </p>
    <p>
      Dřevojan P.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Hradílek Z., Kosorínová M., Lukáč M. and Palice Z. 2020: Zajímavé
      bryofloristické nálezy XXXIII. <em>Bryonora</em> 65, 28 – 31.
    </p>
    <p>
      Chytrý M., Hájek M., Kočí M., Pešout P.,&nbsp;
      <Link to="/people/" state={{ person: "rolecek" }}>
        Roleček J.
      </Link>
      , Sádlo J., Šumberová K., Sychra J., Boublík K., Douda J., Grulich V.,
      Härtel H., Hédl R. , Lustyk P., Navrátilová J., Novák P., Peterka T.,
      Vydrová A. &amp; Chobot K. 2020: Červený seznam biotopů České
      republiky.&nbsp;
      <em>Příroda</em>
      &nbsp;1 – 172.
    </p>
    <p>
      <Link to="/people/" state={{ person: "frankova" }}>
        Fránková M.
      </Link>
      &nbsp;2020: Rozhovor s RNDr. Petrem Marvanem, CSc. <em>Botanika</em> 2020,
      5 – 7.
    </p>
    <p>
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      &nbsp;2020: Víme, kolik bylo smrku v našich lesích před intenzifikací
      hospodaření? <em>Botanika</em> 8, 2 – 4.
    </p>
    <p>
      Peterka T.,&nbsp;
      <Link to="/people/" state={{ person: "hajkova" }}>
        Hájková P.
      </Link>
      , Mikulášková E., Aunina L., Dítě D., Pawlikowski P., Štechová T. and
      Hájek M. 2020: Vegetation affinity of the moss species Meesia triquetra,
      Paludella squarrosa, Pseudocalliergon trifarium and Scorpidium scorpioides
      across European regions.&nbsp;
      <em>Nova Hedwigia</em>
      &nbsp;150, 133 – 158.&nbsp;
    </p>
  </>
);
